import { useEffect } from 'react';
// import MapContext from "../Map/MapContext";
import { Draw, Modify, Snap } from 'ol/interaction';
import OLVectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';
import WKT from 'ol/format/WKT';
import { gisAction } from '../../../../store/gis';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'ol/Overlay';
import { getArea } from 'ol/sphere';

function DrawOnMap({ type }) {
  const totalArea = useSelector((state) => state.gis.area);
  // const { map } = useContext(MapContext);
  const map = useSelector((state) => state.gis.map);
  const dispatch = useDispatch();
  const source = new VectorSource({ wrapX: false });

  useEffect(() => {
    if (!map) return;
    if (totalArea) {
      dispatch(gisAction.updateArea(null));
    }
    let wktFormat = new WKT({
      splitcollection: true,
    });
    if (type !== '') {
      let vectorLayer = new OLVectorLayer({
        source,
      });
      vectorLayer.set('name', 'drawLayer');
      let drawObj = new Draw({
        source: source,
        type: type,
      });
      dispatch(gisAction.updateDraw(drawObj));

      const calculateArea = function (polygon) {
        const cloned = polygon.clone();
        cloned.transform('EPSG:32643', 'EPSG:3857');
        const area = getArea(cloned);
        return area;
      };

      const formatArea = function (area) {
        let output = area * 0.000247105; //convert to acres
        return output.toFixed(3) + ' Acres';
      };

      drawObj.on('drawstart', function (evt) {
        if (source.getFeatures().length > 0) source.clear();

        // ----
        let tooltipCoord = evt.coordinate;
        let sketch = evt.feature;
        sketch.getGeometry().on('change', function (evt) {
          const geom = evt.target;
          const area = calculateArea(geom);
          const output = formatArea(area);
          // dispatch(gisAction.updateArea(output))
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        });
      });

      drawObj.on('drawend', function (evt) {
        let sketch = evt.feature;
        const area = calculateArea(sketch.getGeometry());
        dispatch(gisAction.updateArea(area));
        sketch.getGeometry().transform('EPSG:32643', 'EPSG:4326');
        let geomWKt = wktFormat.writeGeometry(sketch.getGeometry());
        dispatch(gisAction.updateGeometryWKT(geomWKt));
        sketch.getGeometry().transform('EPSG:4326', 'EPSG:32643');
      });

      const modify = new Modify({ source: source });
      modify.on('modifyend', function (evt) {
        let sketch = evt.features.getArray()[0];
        const area = calculateArea(sketch.getGeometry());
        dispatch(gisAction.updateArea(area));
        sketch.getGeometry().transform('EPSG:32643', 'EPSG:4326');
        let geomWKt = wktFormat.writeGeometry(sketch.getGeometry());
        dispatch(gisAction.updateGeometryWKT(geomWKt));
        sketch.getGeometry().transform('EPSG:4326', 'EPSG:32643');
      });

      /**
       * The measure tooltip element.
       * @type {HTMLElement}
       */
      let measureTooltipElement;

      /**
       * Overlay to show the measurement.
       * @type {Overlay}
       */
      let measureTooltip;

      /**
       * Creates a new measure tooltip
       */
      function createMeasureTooltip() {
        if (measureTooltipElement) {
          measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }
        measureTooltipElement = document.createElement('div');
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        measureTooltip = new Overlay({
          element: measureTooltipElement,
          offset: [0, -15],
          positioning: 'bottom-center',
          stopEvent: false,
          insertFirst: false,
        });
        map.addOverlay(measureTooltip);
      }

      map.addInteraction(modify);
      map.addInteraction(drawObj);
      createMeasureTooltip();
      let snap = new Snap({ source: source });
      map.addInteraction(snap);
      map.addLayer(vectorLayer);
    }
  }, [map, type]);

  return null;
}

export default DrawOnMap;
