import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { gisAction } from "../../../../store/gis";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { transform } from "ol/proj";

import { Point } from "ol/geom";
import * as ol from "ol";
import "./Map.css";
import "../../../../../node_modules/ol/ol.css";

const ComparisonMaps = ({ children, zoom, center, trackLocation, isMap2 = false }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState();
  const map1Ref = useRef();
  const map2Ref = useRef();
  proj4.defs("EPSG:32643", "+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs");
  register(proj4);
  React.useEffect(() => {
    var op = {
      view: new ol.View({
        projection: "EPSG:32643",
        zoom,
        center: center,
      }),
      layer: [],
      layers: [],
      controls: [],
      overlays: [],
    };
    let mapObj = new ol.Map(op);
    mapObj.setTarget(isMap2 ? map2Ref.current : map1Ref.current);
    dispatch(
      isMap2 ? gisAction.updateICMap2(mapObj) : gisAction.updateICMap1(mapObj)
    );

    if (trackLocation && center === null) {
      const geolocation = new ol.Geolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: mapObj.getView().getProjection(),
      });
      navigator.geolocation.watchPosition(
        function (pos) {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          var geom = new Point(transform(coords, "EPSG:4326", "EPSG:32643"));
          mapObj.getView().fit(geom.getExtent(), {
            maxZoom: 18,
            duration: 500,
          });
        },
        function (error) {
          alert(`ERROR: ${error.message}`);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }

    setMap(mapObj);
    mapObj.on("click", (evt) => {
      // console.log(evt.coordinate);
    });
    return () => mapObj.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(transform(center, "EPSG:4326", "EPSG:32643"));
  }, [center]);

  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);

  return (
    <>
      {isMap2 ? (
        <div ref={map2Ref} className="map">
          {children}
        </div>
      ) : (
        <div ref={map1Ref} className="map">
          {children}
        </div>
      )}
    </>
  );
};
export default ComparisonMaps;
